/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, jsx, useColorMode } from 'theme-ui'
import WigglyHeroCaptions from '~/components/Content/About/hero'
import TwoColumnGallery from '~/components/Content/Gallery/TwoColumnGallery'
import Header from '~/components/Content/About/header'
import ThreeColumnGallery from '~/components/Content/Gallery/ThreeColumnGallery'

import AboutFooter from '~/components/Content/About/footer'
import { useEffect } from 'react'

const ContentAboutPage = ({ communityGallery, founderGallery, page }) => {
  const media = page.sections[0].media
  const [, setColorMode] = useColorMode()
  //run on mount
  useEffect(() => {
    setColorMode('Our-Story')
  }, [])
  return (
    <Box
      sx={{ isolation: 'isolate', '--purple': '#8821EB', '--blue': '#87EFD3' }}
    >
      <Header
        hero={page?.hero}
        background="var(--blue)"
        color="var(--purple)"
      />
      <WigglyHeroCaptions media={media[0]} color="var(--purple)" />

      <TwoColumnGallery
        gallery={founderGallery}
        background="var(--purple)"
        altbackground="var(--blue)"
        color="var(--blue)"
      />
      <ThreeColumnGallery
        gallery={communityGallery}
        limit={6}
        color="var(--purple)"
        altbackground="var(--purple)"
        background="var(--blue)"
      />
      <WigglyHeroCaptions media={media[1]} color="var(--purple)" />
      <AboutFooter
        media={media[2]}
        color="var(--purple)"
        background="var(--blue)"
      />
    </Box>
  )
}

export default ContentAboutPage

ContentAboutPage.propTypes = {
  founderGallery: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.array
  }),
  communityGallery: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.array
  }),
  page: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        media: PropTypes.arrayOf(PropTypes.shape({})),
        colorTheme: PropTypes.shape({
          slug: PropTypes.string
        })
      })
    )
  })
}
