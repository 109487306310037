/** @jsx jsx */
import { Box, Heading, jsx } from 'theme-ui'
import WigglyHero from '~/components/Content/WigglyHero'

const WigglyHeroCaptions = ({ media, color }) => {
  return (
    <div sx={{ position: 'relative', zIndex: -1 }}>
      <Box
        sx={{
          '--bgColor': '#87EFD3',
          backgroundColor: 'var(--bgColor)',
          isolation: 'isolate',
          color: color ?? 'inherit'
        }}
      >
        <WigglyHero
          objectPosition="center top"
          hero={media}
          color="var(--bgColor)"
        />
        <Box sx={{ px: ['30px', null, '290px'] }}>
          <Box
            sx={{
              color: 'inherit',
              margin: 'auto',
              pt: ['60px', null, '90px'],
              pb: ['34px', null, '56px']
            }}
          >
            <Heading
              as="h3"
              variant="h3"
              sx={{
                color: 'inherit',
                mb: '30px',
                textAlign: 'center'
              }}
              dangerouslySetInnerHTML={{ __html: media.heading?.heading }}
            />
            <Box
              sx={{
                color: 'inherit',
                textAlign: 'center',
                fontSize: ['18px', null, '20px'],
                lineHeight: ['22px', null, '24px']
              }}
              dangerouslySetInnerHTML={{
                __html: media.subheading?.subheading
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default WigglyHeroCaptions
