/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import { Box, Flex, Heading, Grid, jsx } from 'theme-ui'
import BottomWiggle from '~/assets/images/BottomWiggle.svg'
import { useState, useEffect, useRef } from 'react'
import ImgLoader from '~/components/Generic/ImgLoader'

const ThreeColumnGallery = ({
  gallery,
  background,
  altbackground,
  limit,
  color
}) => {
  const startRef = useRef(0)
  const endRef = useRef(limit)
  const [disabled, setDisabled] = useState(false)
  const [loadedGallery, setLoadedGallery] = useState(
    gallery.images.slice(startRef.current, endRef.current)
  )

  useEffect(() => {
    if (endRef.current > gallery.images.length) {
      setDisabled(true)
    }
  }, [endRef.current])

  const fetchMorePics = () => {
    if (endRef.current <= gallery.images.length) {
      startRef.current = endRef.current
      endRef.current = endRef.current + limit

      setLoadedGallery([
        ...loadedGallery,
        ...gallery.images.slice(startRef.current, endRef.current)
      ])
    }
  }

  return (
    <Box
      sx={{
        background: background,
        pb: ['60px', null, '90px'],
        color: color ?? 'inherit'
      }}
    >
      <BottomWiggle
        sx={{
          position: 'relative',
          zIndex: 0,
          mt: '-1px',
          width: '100vw',
          height: 'auto',
          pb: ['60px', null, '90px'],
          path: {
            fill: altbackground
          }
        }}
      />
      <Heading
        as="h3"
        variant="h3"
        sx={{
          textAlign: 'center',
          mb: ['30px', null, '60px'],
          color: 'inherit'
        }}
      >
        {gallery.title}
      </Heading>
      <Grid
        sx={{
          gridTemplateColumns: [
            'clamp(90.5px, 40vw, 370px) clamp(90.5px, 40vw, 370px)',
            'clamp(90.5px, 25vw, 370px) clamp(90.5px, 25vw, 370px) clamp(142.5px, 25vw, 370px)'
          ],
          gridAutoFlow: 'row',
          columnGap: ['30px', '60px'],
          width: 'fit-content',
          mb: ['30px', null, '60px'],
          height: '100%',
          margin: 'auto'
        }}
      >
        {loadedGallery.map((item, index) => {
          return (
            <div key={item.id} sx={{ opacity: 1 }}>
              <ImgLoader
                image={item.image}
                forwardSx={{
                  '@keyframes opacity': {
                    '0%, 20%': {
                      transform: 'translateY(-15px) rotateY(-10deg)',
                      opacity: 0
                    },
                    '100%': {
                      transform: 'translateY(0) rotateX(0)',
                      opacity: 1
                    }
                  },
                  opacity: 1,
                  width: '100%',
                  height: 0,
                  pb: '100%',
                  maxHeight: 'clamp(142.5, 30vw, 462px)',
                  maxWidth: 'clamp(142.5, 30vw, 462px)',
                  mb: ['30px', '60px']
                }}
                imgStyle={{ borderRadius: '8px' }}
              />
            </div>
          )
        })}
      </Grid>
      <Flex sx={{ justifyContent: 'center' }}>
        <button
          ontouchstart=""
          sx={{
            height: '50px',
            background: disabled ? 'transparent' : '#8821EB',
            px: '36px',
            borderRadius: '32px',
            border: disabled ? '3px solid #8821EB' : 'none',
            fontFamily: 'body',
            fontWeight: 'header',
            fontSize: '20px',
            color: disabled ? '#8821EB' : '#87EFD3',
            cursor: !disabled && 'pointer',
            transition: '.5s',
            transitionTimingFunction: 'cubic-bezier(.18,.19,0,1.63)',

            '&:after': {
              content: disabled ? "''" : '"+"',
              fontWeight: 500,
              fontFamily: 'sans serif',
              pb: '12px',
              pl: '9px'
            }
          }}
          onClick={fetchMorePics}
        >
          {disabled ? 'End of content' : 'Load More'}
        </button>
      </Flex>
    </Box>
  )
}

export default ThreeColumnGallery
