import { Box } from 'theme-ui'
import React from 'react'
import ContentHero from '~/components/Content/Hero'

const WigglyHero = ({ objectPosition = 'center center', hero, color }) => {
  return (
    <Box sx={{ backgroundColor: color }}>
      <ContentHero
        objectPosition={objectPosition}
        ignoreText={true}
        hero={hero}
        sx={{
          position: 'relative',
          zIndex: 3,
          maskImage: 'url(/images/wiggle-mask.svg)',
          maskSize: hero?.image?.file?.contentType.includes('video')
            ? ['100% 98%', '100% 100%']
            : '100% 100%',
        }}
      />
    </Box>
  )
}

export default WigglyHero
