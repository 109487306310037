/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'
import Img from 'gatsby-image'

const AboutFooter = ({ background, color, media }) => {
  return (
    <Flex
      sx={{
        color: color ?? 'inherit',
        flexDirection: ['column', null, 'row'],
        background: background ?? 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        gap: ['30px', null, '60px'],
        px: ['30px', null, '200px'],
        pb: ['60px', null, '90px']
      }}
    >
      <Box
        sx={{
          height: ['100%', null, '450px'],
          width: ['100%', null, '450px'],
          maxWidth: '450px'
        }}
      >
        <Img
          fluid={media.media?.fluid}
          imgStyle={{ borderRadius: '8px' }}
          sx={{
            img: { objectFit: 'contain' }
          }}
        />
      </Box>
      <Box
        sx={{
          flexShrink: 10,
          maxWidth: ['100%', null, '533px'],
          textAlign: ['center', null, 'left'],
          color: 'inherit'
        }}
      >
        <Box
          sx={{
            color: 'inherit',
            h3: {
              variant: 'text.h3',
              color: 'inherit',
              margin: 0,
              mb: '30px'
            },
            p: {
              color: 'inherit',
              fontSize: 3,
              lineHeight: '24px',
              fontWeight: 'body',
              margin: 0
            }
          }}
          dangerouslySetInnerHTML={{
            __html: media.description.childMarkdownRemark.html
          }}
        />
      </Box>
    </Flex>
  )
}

export default AboutFooter
