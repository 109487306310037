/** @jsx jsx */
import BottomWiggle from '~/assets/images/BottomWiggle.svg'

import { Box, Heading, jsx, Grid } from 'theme-ui'
import ImgLoader from '~/components/Generic/ImgLoader'

const TwoColumnGallery = ({ gallery, background, altbackground, color }) => {
  return (
    <Box
      sx={{
        background: background,
        color: color ?? 'inherit'
      }}
    >
      <BottomWiggle
        sx={{
          position: 'relative',
          zIndex: 0,
          width: '100vw',
          height: 'auto',
          mt: '-1px',
          pb: ['60px', null, '90px'],
          path: {
            fill: altbackground
          }
        }}
      />
      <Box sx={{ px: '30px', margin: 'auto' }}>
        <Heading
          as="h3"
          variant="h3"
          sx={{
            textAlign: 'center',
            mb: ['30px', null, '60px'],
            color: 'inherit'
          }}
        >
          {gallery.title}
        </Heading>
        <Grid
          sx={{
            gridTemplateColumns: [
              '1fr',
              'minmax(315px, 1fr) minmax(315px, 1fr)'
            ],
            gridAutoFlow: 'row',
            columnGap: [null, '60px', '120px'],
            width: 'fit-content',
            margin: 'auto'
          }}
        >
          {gallery.images.map(item => {
            return (
              <Box key={item.id}>
                <ImgLoader
                  image={item.image}
                  forwardSx={{
                    height: '0',
                    width: '100%',
                    pb: '100%',
                    maxHeight: 'clamp(315px, 30vw, 462px)',
                    maxWidth: 'clamp(315px, 30vw, 462px)',
                    minWidth: [null, null, '315px'],
                    minHeight: [null, null, '315px'],
                    mb: '30px'
                  }}
                  imgStyle={{ borderRadius: '8px' }}
                />

                <Heading
                  sx={{
                    color: 'inherit',
                    mb: '15px',
                    fontSize: ['22px', null, '24px'],
                    fontWeight: 'heading',
                    maxWidth: 'clamp(315px, 30vw, 462px)'
                  }}
                >
                  {item.title}
                </Heading>
                <Box
                  sx={{
                    maxWidth: 'clamp(315px, 30vw, 462px)',
                    color: 'inherit',
                    mb: '15px',
                    fontWeight: 'extraBold',
                    fontSize: ['12px', null, '14px']
                  }}
                >
                  {item.subtitle}
                </Box>
                <Box
                  sx={{
                    color: 'inherit',
                    maxWidth: 'clamp(315px, 30vw, 462px)',
                    fontSize: '18px',
                    fontWeight: 'body',
                    lineHeight: '22px',
                    mb: '60px'
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.description.childMarkdownRemark.html
                  }}
                />
              </Box>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default TwoColumnGallery
